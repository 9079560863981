import { Button, Col, Image, Row, Typography } from "antd";
import "./styles.css";
import { BackArrow } from "assets/jsx-svg";
import LoginToService from "./LoginToService";

import soundWave from "assets/svgs/SoundWaveSVG.svg";
import logo from "assets/images/logo.png";
import { useEffect } from "react";

export default function CustomerService({
  setSelectedService,
  userData,
  setUserData,
}) {
  const directCall = () => {
    window.open(userData.callLink, "_blank");
  };
  useEffect(() => {
    userData?.callLink && window.open(userData.callLink, "_blank");
  }, [userData]);
  return (
    <section className="widget-service-section">
      <div className="service-section-header"></div>
      <div
        className="service-section-main"
        style={{
          background: userData.email && "transparent",
          padding: "14px 0px",
        }}
      >
        {userData.email ? (
          <div>
            <Row align="middle" style={{ paddingInline: "14px" }}>
              <Col>
                <div
                  style={{ width: "18px" }}
                  className="clickable"
                  onClick={() => {
                    setUserData({});
                    setSelectedService(null);
                  }}
                >
                  <BackArrow color="#fff" />
                </div>
              </Col>
            </Row>

            <Row
              justify="center"
              style={{
                marginTop: "18px",
                textAlign: "center",
                paddingInline: "14px",
              }}
            >
              <Col xs={24}>
                <Typography.Text className="fw-500 fz-16 wc">
                  Customer service center
                </Typography.Text>
              </Col>
              <Col xs={24}>
                <Typography.Text className="wc fz-10">
                  Exceptional support for your needs
                </Typography.Text>
              </Col>
            </Row>

            <Row
              justify="center"
              className="customer-service-vindo-wave"
              style={{ background: `url(${soundWave})` }}
            >
              <div className="customer-service-vindo-holder">
                <div className="customer-service-vindo">
                  <div className="customer-service-image">
                    <Image preview={false} src={logo} width={64} height={64} />
                  </div>
                </div>
              </div>
            </Row>

            <Row justify="center">
              <Typography.Text
                className="fz-12 wc"
                style={{ textAlign: "center", paddingInline: "14px" }}
              >
                Click on direct call to transferred you directly to the
                customer services.
              </Typography.Text>
            </Row>

            <Row style={{ paddingInline: "14px", marginTop: "45px" }}>
              <Button
                type="ghost"
                style={{ background: "#FAFAFA" }}
                className="w-100"
                onClick={() => directCall()}
              >
                Direct Call
              </Button>
            </Row>
          </div>
        ) : (
          <LoginToService
            setSelectedService={setSelectedService}
            setUserData={setUserData}
            userData={userData}
          />
        )}
      </div>
    </section>
  );
}
