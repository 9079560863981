import { useRef, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Dropdown } from "antd";

import Widget from "components/Widget";
import VindoRouter from "router";
import {
  AppsSVG,
  CallSVG,
  DiskCustomerServiceSVG,
  DiskTicketSVG,
  WhiteChatSVG,
} from "assets/jsx-svg";
import WidgetService from "services/widget.service";

import CompanyContext from "context/companyContext";
import axios from "axios";

function App() {
  const [company, setCompany] = useState({});
  const widgetBtn = useRef();
  const [widgetOpen, setWidgetOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [searchParamas] = useSearchParams();
  const [widgetData, setWidgetData] = useState({});
  const [allowedServices, setAllowedServices] = useState([]);

  useEffect(() => {
    const isAutoOpen = searchParamas.get("isAutoOpen");
    setWidgetOpen(isAutoOpen == "true");

    (async () => {
      const hash = searchParamas.get("hash");

      if (hash) {
        axios.defaults.headers.hash = hash;
        const res = await WidgetService.getData(hash);
        setWidgetData(res.data.data);
        if (res.data.data.services) {
          setAllowedServices(res.data.data.services.split(","));
        }
        setCompany(res.data.data);
      }
    })();
  }, [searchParamas, setCompany]);

  return (
    <CompanyContext.Provider value={{ company, setCompany }}>
      <div>
        <VindoRouter />
        {allowedServices.length ? (
          <div className="main-widget-chat">
            <Dropdown
              trigger={["click"]}
              dropdownRender={() => (
                <Widget
                  selectedServices={allowedServices}
                  setWidgetOpen={setWidgetOpen}
                  selectedService={selectedService}
                  setSelectedService={setSelectedService}
                />
              )}
              placement="topLeft"
              open={widgetOpen}
              getPopupContainer={() => widgetBtn.current}
            >
              <div
                ref={widgetBtn}
                className="center-items widget-main-icon clickable"
                onClick={() => {
                  if (!widgetOpen) {
                    window.parent.postMessage("openIframeStyle", "*");
                  } else {
                    window.parent.postMessage("closeIframeStyle", "*");
                  }

                  setWidgetOpen((prev) => !prev);
                }}
                style={{
                  background:
                    selectedService && servicesType[selectedService].color,
                }}
              >
                {selectedService ? (
                  servicesType[selectedService].icon
                ) : (
                  <AppsSVG />
                )}
              </div>
            </Dropdown>
          </div>
        ) : null}
      </div>
    </CompanyContext.Provider>
  );
}

const servicesType = {
  CHAT: {
    icon: (
      <WhiteChatSVG color="#fff" style={{ width: "16px", height: "16px" }} />
    ),
    color: "#A620F5",
  },
  CALL: {
    icon: <CallSVG color="#fff" style={{ width: "16px", height: "16px" }} />,
    color: "#3F65E4",
  },
  MEET: {
    icon: (
      <DiskCustomerServiceSVG
        color="#fff"
        style={{ width: "16px", height: "16px" }}
      />
    ),
    color: "#F82E8E",
  },
  TICKET: {
    icon: (
      <DiskTicketSVG color="#fff" style={{ width: "16px", height: "16px" }} />
    ),
    color: "#9CDBF6",
  },
};

export default App;
