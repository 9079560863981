import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Col,
  Form,
  Image,
  Input,
  Row,
  Typography,
  message,
} from "antd";
import dayjs from "utils/dayjs";

import TextUrl from "components/TextUrl";

import logo from "assets/images/logo.png";

import "./styles.css";
import { AttatchSVG, SendSVG } from "assets/jsx-svg";
import {
  child,
  getDatabase,
  increment,
  onChildAdded,
  onChildRemoved,
  push,
  ref,
  set,
  update,
} from "firebase/database";
import { firebaseConfig } from "utils/firebase.utils";
import { initializeApp } from "firebase/app";
import companyContext from "context/companyContext";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { axiosCatch } from "utils/axiosUtils";
import CommonService from "services/common.service";

const isToday = require("dayjs/plugin/isToday");

dayjs.extend(isToday);

export default function Chat({ userData, setUserData, setSelectedService }) {
  const attachmentInputRef = useRef();
  const { company } = useContext(companyContext);
  const [form] = Form.useForm();
  const db = getDatabase();
  const messagesEndRef = useRef();
  const [messages, setMessages] = useState([
    {
      fromVindo: true,
      date: dayjs().valueOf(),
      message: "Hi there! How can I help you today?",
    },
  ]);
  const [channelId, setChannelId] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  useEffect(() => {
    initializeApp(firebaseConfig);
  }, []);

  const createChannel = useCallback(
    (files) => {
      try {
        const channel = push(child(ref(db), "channels")).key;
        const newEmail = userData.email.replace(/\./g, ",");
     
        const customerRef = ref(db, `Company/${company.id}/customer/${newEmail}`);
        const unAssignedChatsRef = ref(
          db,
          `Company/${company.id}/unAssignedChats/${channel}`,
        );
        const newPostKey = push(customerRef).key;
        setChannelId(channel);

        const customerData = {
          [newPostKey]: channel,
        };

        update(customerRef, customerData);
        set(unAssignedChatsRef, channel);
        if (files) {
          onUploadFile(files, channel);
        }
      } catch (e) {
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [db, userData.email, userData.name],
  );

  const handAnyChanged = useCallback(() => {
    if (channelId) {
      const referencenew = ref(
        db,
        `Company/${company.id}/chats/${channelId}/messages`,
      );

      onChildAdded(referencenew, (data) => {
        console.log("//////////////", data.val());
        setMessages((prev) => [...prev, { owner: true, ...data.val() }]);
      });
    }
  }, [db, channelId]);

  useEffect(() => {
    handAnyChanged();
  }, [handAnyChanged]);

  useEffect(() => {
    if (channelId) {
      const referencenew = ref(db, `Company/${company.id}/chats`);

      onChildRemoved(referencenew, (chatRemoved) => {
        const value = chatRemoved.val();
        if (value?.info?.chatId) {
          setUserData({});
          setSelectedService(null);
          setChannelId("");
          message.info("Your Chat Has Been Archieved");
        }
      });
    }
  }, [channelId, company.id, db, setSelectedService, setUserData]);

  const onFinish = (values) => {
    if (channelId && values.newMessage) {
  
      setLoading(true);
      const messagesRef = ref(
        db,
        `Company/${company.id}/chats/${channelId}/messages`,
      );
      const infoRef = ref(db, `Company/${company.id}/chats/${channelId}/info`);

      const newPostRef = push(messagesRef);
      set(newPostRef, {
        message: values.newMessage,
        date: dayjs().valueOf(),
      });

      const updates = {};

      updates[`/numberOfUnreadMessages`] = increment(1);
      updates[`/date`] = dayjs().valueOf();
      updates[`/lastMessage`] = values.newMessage;
      if (userData.name) {
        updates[`/name`] = userData.name;
      }
      if (userData.email) {
        updates[`/email`] = userData.email;
      }
      updates[`/chatId`] = channelId;
      update(infoRef, updates).finally(() => setLoading(false));

      form.setFieldValue("newMessage", "");
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const onUploadFile = async (fileList, id) => {
    console.log(channelId);
    if (fileList[0] && (channelId || id)) {
      console.log(channelId);
      if (fileList[0].size / 1024 > 4096) {
        message.info("File must be less than 4MB");
        return;
      }

      try {
        setFileLoading(true);
        const res = await CommonService.uploadFile(fileList[0]);

        const infoRef = ref(
          db,
          `Company/${company.id}/chats/${channelId || id}/info`,
        );
        const filesRef = ref(
          db,
          `Company/${company.id}/chats/${channelId || id}/files`,
        );
        const messagesRef = ref(
          db,
          `Company/${company.id}/chats/${channelId || id}/messages`,
        );

        const newPostRef = push(messagesRef);
        set(newPostRef, {
          message: fileList[0].name,
          file: res.data.uploadedFiles.file,
          date: dayjs().valueOf(),
        });

        const newFileRef = push(filesRef);
        set(newFileRef, {
          message: fileList[0].name,
          file: res.data.uploadedFiles.file,
          size: fileList[0].size,
          date: dayjs().valueOf(),
        });

        const updates = {};

        updates[`/numberOfUnreadMessages`] = increment(1);
        updates[`/date`] = dayjs().valueOf();
        updates[`/lastMessage`] = fileList[0].name;
        if (userData.name) {
          updates[`/name`] = userData.name;
        }
        if (userData.email) {
          updates[`/email`] = userData.email;
        }
        updates[`/chatId`] = channelId || id;
        update(infoRef, updates);
      } catch (err) {
        axiosCatch(err);
      } finally {
        setFileLoading(false);
      }
    }
  };

  return (
    <main className="chat-main">
      <div
        style={{
          overflowY: "auto",
          marginTop: "2px",
          paddingInlineEnd: "0.2rem",
          overflowX: "hidden",
          maxHeight: "320px",
        }}
      >
        {messages.length > 0 && (
          <Row justify="center">
            <Typography.Text className="fz-10" style={{ color: "#AEAEB2" }}>
              {dayjs().isToday()
                ? `Today, ${dayjs().format("HH:mm a")}`
                : `${dayjs().format("HH:mm a")}`}
            </Typography.Text>
          </Row>
        )}
        {messages.map((message) => (
          <Row
            key={message.date}
            justify={message.fromVindo ? "start" : "end"}
            gutter={message.fromVindo ? [6, 0] : 0}
            wrap={false}
            align="middle"
            style={{ margin: "12px 0" }}
          >
            {message.fromVindo && (
              <Col>
                <Avatar
                  size={32}
                  src={logo}
                  style={{
                    background: "#fff",
                    boxShadow: "0px 0px 6px #0000001A",
                    objectFit: "cover",
                  }}
                />
              </Col>
            )}
            <Col style={{ maxWidth: "180px" }}>
              <Row justify={message.fromVindo ? "start" : "end"}>
                <div
                  className={`chat-message ${message.file && "clickable"}`}
                  style={{
                    borderRadius: !message.fromVindo && "12px 12px 4px 12px",
                    textAlign: "left",
                    background: !message.fromVindo && "#9A42A9",
                  }}
                  onClick={() => {
                    if (message.file) {
                      window.open(message.file);
                    }
                  }}
                >
                  {message.file ? (
                    <>
                      {["jpg", "jpeg", "png", "webp"].includes(
                        message.file.split(".")[
                          message.file.split(".").length - 1
                        ],
                      ) ? (
                        <Image
                          style={{
                            maxWidth: "200px",
                            maxHeight: "400px",
                            borderRadius: "12px",
                          }}
                          src={message.file}
                          preview={false}
                        />
                      ) : (
                        <>
                          {" "}
                          <Typography.Text
                            className="fz-12"
                            style={{
                              color: !message.fromVindo && "#fff",
                            }}
                          >
                            {message.message}
                          </Typography.Text>
                          <DownloadOutlined
                            style={{
                              color: !message.fromVindo && "#fff",
                              marginInlineStart: "6px",
                            }}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <TextUrl
                      text={message.message}
                      style={{ color: !message.fromVindo && "#fff" }}
                    />
                  )}
                </div>
              </Row>
            </Col>

            <div ref={messagesEndRef} />
          </Row>
        ))}
      </div>

      <Form
        form={form}
        onSubmitCapture={() => {
          if (!loading) {
            if (!channelId) {
              createChannel();
            }
          }
        }}
        onFinish={onFinish}
        style={{ marginTop: "12px" }}
      >
        <Row align="middle" wrap={false}>
          <Col flex={1}>
            <Row align="middle" gutter={[8, 0]} wrap={false}>
              <Col flex={1}>
                <Row
                  align="middle"
                  style={{ background: "#fff", borderRadius: "8px" }}
                  wrap={false}
                  gutter={[4, 0]}
                >
                  <Col flex={1}>
                    <Form.Item name="newMessage" initialValue={""} noStyle>
                      <Input
                        style={{ borderRadius: "12px", border: "none" }}
                        placeholder="Type a message "
                        onPressEnter={() =>
                          messagesEndRef.current?.scrollIntoView({
                            behavior: "smooth",
                          })
                        }
                        autoComplete={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <input
                      type="file"
                      id="attatch"
                      name="attatch"
                      style={{ display: "none" }}
                      ref={attachmentInputRef}
                      onChange={(e) => {
                        if (!channelId) {
                          createChannel(e.target.files);
                        } else {
                          onUploadFile(e.target.files);
                        }
                      }}
                    />
                    <Row align="middle">
                      {fileLoading ? (
                        <LoadingOutlined />
                      ) : (
                        <AttatchSVG
                          className="clickable"
                          onClick={() => {
                            if (!fileLoading) {
                              attachmentInputRef?.current?.click();
                            }
                          }}
                        />
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col>
                <div
                  className="chat-send-btn"
                  onClick={() => {
                    if (!loading) {
                      if (!channelId) {
                        createChannel();
                        form.submit();
                      } else if (form.getFieldValue("newMessage")) {
                        form.submit();
                      }
                    }
                  }}
                  style={{ pointerEvents: loading && "none" }}
                >
                  {loading ? <LoadingOutlined /> : <SendSVG />}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </main>
  );
}
