import { useContext, useEffect, useState } from "react";
import {
  Col,
  Form,
  Input,
  Row,
  Tooltip,
  Typography,
  Upload,
  message,
  notification,
} from "antd";
import {
  LoadingOutlined,
  PaperClipOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { LIST_IGNORE } from "antd/es/upload/Upload";

import CustomerTicketService from "services/customerTicket.service";
import { BackArrow, DeleteSVG, LeftArrowSVG } from "assets/jsx-svg";
import { axiosCatch } from "utils/axiosUtils";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "utils/firebase.utils";
import { getDatabase, ref, update } from "firebase/database";
import companyContext from "context/companyContext";

export default function TicketSection({ setSelectedService, setUserData }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const db = getDatabase();
  const { company } = useContext(companyContext);
  useEffect(() => {
    initializeApp(firebaseConfig);
  }, []);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      uploadedFiles.forEach((file, index) => {
        formData.append(index, file);
      });
      formData.append("email", values.email);
      formData.append("fullName", values.email.split("@")[0]);
      formData.append("title", values.title);
      formData.append("content", values.description);

      const res = await CustomerTicketService.add(formData);

      notification.success({ message: "Ticket submited successfully" });
      setSelectedService(null);
      const ticketsRef = ref(db, `Company/${company.id}/ticket`);

      const newPostsKey = company.id;
      update(ticketsRef, { [newPostsKey]: new Date() });
      window.open(res.data.data.link, "_blank");
    } catch (err) {
      axiosCatch(err);
    } finally {
      setLoading(false);
    }
  };

  const onFileDelete = (id) => {
    const filteredFiles = uploadedFiles.filter((file) => file.uid !== id);

    setUploadedFiles(filteredFiles);
    form.setFieldValue("uploadedFiles", { fileList: filteredFiles });
  };

  return (
    <section className="widget-service-section">
      <div className="service-section-header"></div>
      <div className="service-section-main">
        <div
          style={{ width: "18px" }}
          className="clickable"
          onClick={() => {
            setUserData({});
            setSelectedService(null);
          }}
        >
          <BackArrow color="#000" />
        </div>

        <Row justify="center" style={{ textAlign: "center" }}>
          <Col xs={24}>
            <Typography.Text className="fw-500 fz-16">
              Submit ticket
            </Typography.Text>
          </Col>
          <Col xs={24}>
            <Typography.Text style={{ color: "#8E8E93" }} className="fz-10">
              Share your issue by providing details
            </Typography.Text>
          </Col>
        </Row>

        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{ marginTop: "8px" }}
        >
          <Form.Item
            name="email"
            label="Enter Your E-mail"
            rules={[{ required: true, message: "Please Enter Email" }]}
          >
            <Input placeholder="ex: ahmed@gmail.com" />
          </Form.Item>

          <Form.Item
            name="title"
            label="Ticket Title"
            rules={[{ required: true, message: "Please Enter Ticket Title" }]}
          >
            <Input placeholder="Enter Here" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Ticket Description"
            rules={[
              { required: true, message: "Please Enter Ticket Description" },
            ]}
          >
            <Input.TextArea
              placeholder="Enter Description Here"
              style={{
                resize: "none",
              }}
            />
          </Form.Item>

          <Typography.Text>Attachment</Typography.Text>
          <Row justify="space-between" align="middle" wrap={false}>
            <Col>
              <Row justify="start" align="middle" gutter={[4, 4]} wrap={false}>
                <Col>
                  <Form.Item>
                    <Upload
                      multiple={true}
                      name="file"
                      listType="picture-card"
                      showUploadList={false}
                      onChange={(info) => {
                        const foundIndex = uploadedFiles.findIndex(
                          (file) => file.name === info.file.name,
                        );
                        if (foundIndex < 0) {
                          setUploadedFiles((prev) => [...prev, info.file]);
                        }
                      }}
                      beforeUpload={(file) => {
                        if (file.size > 4194304) {
                          message.error("File must be less than 4MB");
                          return LIST_IGNORE;
                        }
                        return false;
                      }}
                      className="ticket-upload"
                    >
                      <PlusOutlined />
                    </Upload>
                  </Form.Item>
                </Col>
                <div style={{ maxHeight: "80px", overflowY: "auto" }}>
                  {uploadedFiles.map((file) => (
                    <Col flex={1}>
                      <Tooltip title={file.name}>
                        <Row align="middle" gutter={[4, 4]} wrap={false}>
                          <Col>
                            <PaperClipOutlined />{" "}
                          </Col>
                          <Col flex={1} style={{ maxWidth: "100px" }}>
                            <Typography.Text ellipsis>
                              {file.name}
                            </Typography.Text>
                          </Col>
                          <Col>
                            <Row align="middle">
                              <DeleteSVG
                                className="clickable"
                                style={{ width: "12px", height: "12px" }}
                                onClick={() => {
                                  onFileDelete(file.uid);
                                }}
                              />
                            </Row>
                          </Col>
                        </Row>
                      </Tooltip>
                    </Col>
                  ))}
                </div>
              </Row>
            </Col>

            <Col>
              <Form.Item>
                <Row justify="end">
                  <button className="start-btn">
                    {loading ? (
                      <LoadingOutlined style={{ color: "#fff" }} />
                    ) : (
                      <LeftArrowSVG style={{ rotate: "180deg" }} />
                    )}
                  </button>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </section>
  );
}
