const CallSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50.1}
    height={40}
    data-name="Iconly/Light-Outline/3-User"
    viewBox="0 0 50 38.559"
    {...props}
  >
    <path
      fill={props.color || "#d1d1d6"}
      fillRule="evenodd"
      d="M25 23.916h.839c4.434.029 14.566.477 14.566 7.344 0 6.822-9.779 7.267-14.5 7.3h-1.743c-4.434-.029-14.568-.476-14.568-7.335 0-6.831 10.134-7.276 14.568-7.305h.58Zm0 3.445c-5.456 0-11.962.671-11.962 3.863 0 3.12 6.118 3.849 11.464 3.891h.5c5.456 0 11.96-.668 11.96-3.854-.001-3.223-6.504-3.9-11.962-3.9Zm18.265-4.377c5.566.834 6.735 3.447 6.735 5.493 0 1.249-.491 3.53-3.775 4.781a1.742 1.742 0 0 1-.613.113A1.722 1.722 0 0 1 45 30.039c1.557-.592 1.557-1.247 1.557-1.562 0-1.006-1.279-1.709-3.8-2.085a1.723 1.723 0 1 1 .512-3.408Zm-34.57 1.447a1.726 1.726 0 0 1-1.449 1.961c-2.522.377-3.8 1.079-3.8 2.085 0 .315 0 .967 1.559 1.562a1.722 1.722 0 0 1-.613 3.332 1.742 1.742 0 0 1-.613-.113C.492 32 0 29.724 0 28.477c0-2.044 1.169-4.66 6.736-5.493a1.734 1.734 0 0 1 1.959 1.447ZM25 0a10.493 10.493 0 1 1 0 20.985h-.062a10.46 10.46 0 0 1-10.429-10.5A10.5 10.5 0 0 1 25 0Zm0 3.445a7.055 7.055 0 0 0-7.046 7.048 7.014 7.014 0 0 0 6.988 7.048H25a7.05 7.05 0 1 0 0-14.1Zm14.213-1.2a8.316 8.316 0 0 1-.2 16.443 1.692 1.692 0 0 1-.239.016 1.723 1.723 0 0 1-.237-3.429 4.871 4.871 0 0 0 .11-9.632 1.723 1.723 0 1 1 .56-3.4ZM12.768 3.669a1.723 1.723 0 0 1-1.419 1.98 4.871 4.871 0 0 0 .108 9.632 1.723 1.723 0 0 1-.237 3.429 1.692 1.692 0 0 1-.239-.016 8.317 8.317 0 0 1-.193-16.443 1.713 1.713 0 0 1 1.98 1.418Z"
      data-name="3-User"
    />
  </svg>
);
export default CallSVG;
