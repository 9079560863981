const CalenderSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.41}
    height={16.01}
    viewBox="0 0 14.4 16"
    {...props}
  >
    <path
      fill={props.color || "#fff"}
      fillRule="evenodd"
      d="M10.4 8.8H8a.8.8 0 0 0-.8.8V12a.8.8 0 0 0 .8.8h2.4a.8.8 0 0 0 .8-.8V9.6a.8.8 0 0 0-.8-.8Zm0-8v.8H4V.8a.8.8 0 1 0-1.6 0v.8h-.8A1.593 1.593 0 0 0 .008 3.2L0 14.4A1.6 1.6 0 0 0 1.6 16h11.2a1.6 1.6 0 0 0 1.6-1.6V3.2a1.6 1.6 0 0 0-1.6-1.6H12V.8a.8.8 0 1 0-1.6 0ZM12 14.4H2.4a.8.8 0 0 1-.8-.8v-8h11.2v8a.8.8 0 0 1-.8.8Z"
      data-name="Path 51071"
    />
  </svg>
);

export default CalenderSVG;
