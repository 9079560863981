import * as React from "react";

const ChatCloseSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.01}
    height={14.9}
    viewBox="0 0 16 14.86"
    {...props}
  >
    <path
      fill="#fff"
      d="M3.722 14.695a1.444 1.444 0 0 1-.515-1.239c0-.158 0-.336.01-.539a3.548 3.548 0 0 1-2.182-.923 3.5 3.5 0 0 1-.92-2.138 27.331 27.331 0 0 1-.114-3.339 27.4 27.4 0 0 1 .114-3.342 3.49 3.49 0 0 1 .92-2.134A3.475 3.475 0 0 1 3.172.119a27.4 27.4 0 0 1 3.34-.113h2.977a27.4 27.4 0 0 1 3.34.113 3.473 3.473 0 0 1 2.137.922 3.484 3.484 0 0 1 .92 2.134A27.294 27.294 0 0 1 16 6.517a27.229 27.229 0 0 1-.114 3.339 3.493 3.493 0 0 1-.92 2.138 3.478 3.478 0 0 1-2.137.92 26.83 26.83 0 0 1-3.34.116 5.886 5.886 0 0 0-2.6.789l-.02.009a19.57 19.57 0 0 1-1.761.853 2.3 2.3 0 0 1-.8.184 1.014 1.014 0 0 1-.586-.17Zm.233-3.9a.557.557 0 0 1 .509.6l-.03.35c-.056.606-.1 1.128-.11 1.505a2 2 0 0 0 .034.493 2.571 2.571 0 0 0 .523-.18c.371-.162.858-.412 1.475-.733l.019-.007a6.933 6.933 0 0 1 3.114-.916c2.648 0 3.976 0 4.688-.709s.707-2.036.707-4.686 0-3.976-.707-4.687-2.04-.709-4.687-.709H6.513c-2.648 0-3.977 0-4.688.709s-.707 2.039-.707 4.688 0 3.977.707 4.686a2.426 2.426 0 0 0 1.483.6c0-.05.01-.1.014-.156l.03-.343a.557.557 0 0 1 .554-.51.335.335 0 0 1 .049.005Zm5.344-2.209-1.3-1.3-1.3 1.3a.538.538 0 0 1-.387.16.54.54 0 0 1-.386-.16.548.548 0 0 1 0-.774l1.3-1.3-1.3-1.3a.544.544 0 0 1 0-.771.544.544 0 0 1 .773 0l1.3 1.3 1.3-1.3a.544.544 0 0 1 .774 0 .546.546 0 0 1 0 .771l-1.3 1.3 1.3 1.3a.55.55 0 0 1 0 .774.54.54 0 0 1-.387.16.54.54 0 0 1-.386-.161Z"
      data-name="Path 51134"
    />
  </svg>
);

export default ChatCloseSVG;
