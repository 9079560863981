import { Navigate, Route, Routes } from "react-router-dom";
import Home from "views/Home";

export default function VindoRouter() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
