const PersonSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.17}
    height={14.001}
    viewBox="0 0 11.16 14"
    {...props}
  >
    <path
      fill={props.color || "#1131da"}
      fillRule="evenodd"
      d="M11.16 11.409C11.16 13.731 7.976 14 5.581 14h-.172C3.884 14 0 13.9 0 11.395 0 9.12 3.057 8.816 5.433 8.8h.319c1.526.008 5.408.1 5.408 2.609ZM5.581 9.861c-3 0-4.524.516-4.524 1.534s1.522 1.548 4.524 1.548 4.523-.516 4.523-1.534-1.522-1.548-4.523-1.548Zm0-9.861a3.741 3.741 0 0 1 0 7.482h-.023A3.741 3.741 0 0 1 5.581 0Zm0 1.006a2.735 2.735 0 0 0-.02 5.47h.02a2.735 2.735 0 0 0 0-5.47Z"
      data-name="Iconly/Light-Outline/Profile"
    />
  </svg>
);
export default PersonSVG;
