const DiskCustomerServiceSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50.1}
    height={50}
    viewBox="0 0 50 49.999"
    {...props}
  >
    <path
      fill={props.color || "#d1d1d6"}
      fillRule="evenodd"
      d="M5.754 7.467s-.117.127-.271.278a5.488 5.488 0 0 0-1.824 4.256c-.019 3.49 2.276 9.97 13.327 21.019 11 10.995 17.47 13.317 20.968 13.317H38a5.5 5.5 0 0 0 4.261-1.824 5.7 5.7 0 0 1 .417-.4c2.429-2.444 3.673-4.263 3.661-5.429-.017-1.19-1.5-2.6-3.544-4.544a100.333 100.333 0 0 1-2.1-2.039c-1.934-1.929-2.893-1.6-5-.859-2.915 1.022-6.914 2.412-13.129-3.8s-4.832-10.214-3.812-13.129c.737-2.107 1.073-3.068-.863-5-.759-.756-1.439-1.473-2.066-2.132-1.934-2.034-3.329-3.5-4.512-3.522h-.02c-1.168 0-2.983 1.249-5.551 3.817.007-.007.01-.01.012-.01Zm32.202 42.534c-6.078 0-14-4.839-23.555-14.387C4.81 26.025-.036 18.076 0 11.986a9.085 9.085 0 0 1 2.925-6.858c.041-.051.183-.19.232-.239 3.5-3.5 5.856-4.915 8.2-4.888 2.724.037 4.663 2.076 7.117 4.659.607.639 1.266 1.334 2 2.066 3.561 3.561 2.546 6.466 1.732 8.8-.888 2.544-1.656 4.739 2.944 9.339s6.8 3.832 9.334 2.937c2.334-.815 5.232-1.834 8.8 1.727.716.718 1.406 1.372 2.038 1.972 2.6 2.466 4.644 4.415 4.678 7.146.029 2.329-1.386 4.7-4.878 8.2l-.239.229a9.078 9.078 0 0 1-6.859 2.925Z"
      data-name="Iconly/Light-Outline/Call"
    />
  </svg>
);
export default DiskCustomerServiceSVG;
