const CheckSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.01}
    height={10.5}
    viewBox="0 0 14 10.565"
    {...props}
  >
    <path
      fill={props.color || "#8e8e93"}
      d="M3.91 10.153.41 6.631a1.415 1.415 0 0 1 0-1.992 1.394 1.394 0 0 1 1.979 0L4.9 7.164 11.61.412a1.4 1.4 0 0 1 1.98 0 1.414 1.414 0 0 1 0 1.992l-7.7 7.749a1.4 1.4 0 0 1-1.98 0Z"
    />
  </svg>
);
export default CheckSVG;
