import { useCallback, useEffect, useMemo, useState } from "react";

import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { BackArrow } from "assets/jsx-svg";

import CustomerMeetingService from "services/customerMeeting.service";
import CustomerDeskService from "services/customerDesk.service";
import BookingConfirme from "./BookingConfirme";
import { axiosCatch } from "utils/axiosUtils";

import "./styles.css";
import allTimezones from "constants/TIME_ZONES";
import dayjs from "dayjs";

export default function SchedualeCall({ setSelectedService, setUserData }) {
  const [form] = Form.useForm();
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [bookingData, setBookingData] = useState(null);
  const [meetingsTimes, setMeetingsTimes] = useState({});
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [desks, setDesks] = useState([]);
  const deskSelectedId = Form.useWatch("topic", form);
  const deskSelectedData = desks?.filter(
    (item) => item.id == deskSelectedId,
  )[0];
  const getDates = useCallback(async () => {
    if (deskSelectedId) {
      try {
        setFetchLoading(true);
        const res = await CustomerMeetingService.getByDay(
          dayjs(),
          deskSelectedId,
        );
        // Initialize an empty object to store the meetings
        const meetingsByDate = {};

        // Iterate over the appointments and format them as desired
        res.data.data.appointments.forEach((appointment) => {
          const date = appointment.date;
          const meetings = appointment.appointments.map((app) => ({
            fromTime: app.start,
            end: app.end,
          }));
          meetingsByDate[date] = { meetings };
        });
        setMeetingsTimes(meetingsByDate);
      } catch (err) {
        axiosCatch(err);
      } finally {
        setFetchLoading(false);
      }
    }
  }, [deskSelectedId]);

  useEffect(() => {
    getDates();
  }, [getDates, deskSelectedId]);

  const onFinish = async (values) => {
    if (!selectedDay && !deskSelectedData?.aiAgent) {
      message.info("You Must Select Date");
      return;
    }

    if (!selectedTime && !deskSelectedData?.aiAgent) {
      message.info("You Must Select Time");
      return;
    }
    try {
      setLoading(true);
      let bookingData = {
        email: values.email,
        fullName: values.name,
        deskId: values.topic,
        timeZone: values.timeZone,
        date: dayjs(selectedDay)
          .tz(values.timeZone)
          .set("hour", dayjs(selectedTime, "HH").format("HH"))
          ?.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        customerInfo: [],
      };
      if (deskSelectedData?.aiAgent) {
        bookingData.date = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      }
      const res = await CustomerMeetingService.book(bookingData);
      setBookingData({
        name: values.name,
        day: selectedDay,
        time: selectedTime,
        link: res.data.data.meetingLink,
        id: res.data.data.meetingId,
      });
    } catch (err) {
      axiosCatch(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await CustomerDeskService.get("MEETING_DESK");
        setDesks(res.data.data.rows);
      } catch (err) {
        axiosCatch(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  const timezoneOptions = useMemo(() => {
    return allTimezones.map((item) => ({ label: item, value: item }));
  }, []);

  return (
    <section
      className="widget-service-section"
      style={{ height: deskSelectedId && "auto" }}
    >
      <div className="service-section-header"></div>
      <div className="service-section-main" style={{ overflow: "auto" }}>
        <Row align="middle" style={{ textAlign: "center" }}>
          <Col>
            <div
              style={{ width: "18px" }}
              className="clickable"
              onClick={() => {
                setUserData({});
                setSelectedService(null);
              }}
            >
              <BackArrow color="#000" />
            </div>
          </Col>
          <Col flex={1}>
            {!bookingData && (
              <Typography.Text className="fw-500 fz-16">
                Schedule a call
              </Typography.Text>
            )}
          </Col>
        </Row>

        {bookingData ? (
          <BookingConfirme
            timeZone={form.getFieldValue("timeZone")}
            bookingData={bookingData}
            setSelectedService={setSelectedService}
          />
        ) : (
          <Form
            form={form}
            initialValues={{
              timeZone: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone,
            }}
            layout="vertical"
            onFinish={onFinish}
            style={{ marginTop: "6px", paddingInline: "5px" }}
          >
            <Form.Item
              name="email"
              label="Enter Your E-mail"
              rules={[{ required: true, message: "Please Enter Email" }]}
            >
              <Input placeholder="ex: ahmed@gmail.com" />
            </Form.Item>

            <Form.Item
              name="name"
              label="Enter Your Name"
              rules={[
                { required: true, message: "Please Enter Enter Your Name" },
              ]}
            >
              <Input placeholder="ex: ahmed ali" />
            </Form.Item>

            <Form.Item
              name="topic"
              label="Subject or topic"
              rules={[
                { required: true, message: "Please Select Subject or topic" },
              ]}
            >
              <Select
                placeholder="select"
                options={desks?.map((desk) => ({
                  label: desk.name,
                  value: desk.id,
                }))}
              />
            </Form.Item>
            {deskSelectedId && !deskSelectedData?.aiAgent && (
              <>
                {fetchLoading ? (
                  <Form.Item label="loading">
                    <Row justify="center">
                      <LoadingOutlined />
                    </Row>
                  </Form.Item>
                ) : (
                  meetingsTimes && (
                    <>
                      <Form.Item name={"timeZone"} label="TimeZone">
                        <Select showSearch options={timezoneOptions}></Select>
                      </Form.Item>
                      <Form.Item label="Date" required>
                        <div style={{ overflowX: "auto" }}>
                          <Row
                            align="middle"
                            gutter={[8, 0]}
                            style={{
                              flexWrap: "nowrap",
                              paddingBottom: "6px",
                            }}
                          >
                            {Object.keys(meetingsTimes).map((date) => {
                              const isActive = selectedDay
                                ? dayjs(selectedDay).format("DD") ===
                                  dayjs(date).format("DD")
                                : false;
                              return (
                                <Col>
                                  <div
                                    className={`date-item ${
                                      isActive ? "date-item-active" : ""
                                    }`}
                                    key={date}
                                    onClick={() => {
                                      setSelectedDay(date);
                                      setSelectedTime("");
                                    }}
                                  >
                                    <span className="fw-600">
                                      {dayjs(date).format("DD")}
                                    </span>
                                    {dayjs(date).format("ddd")}
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      </Form.Item>

                      <Form.Item label="Time" required>
                        {selectedDay &&
                        meetingsTimes[selectedDay].meetings.length > 0 ? (
                          <Row
                            align="middle"
                            gutter={[8, 0]}
                            style={{
                              flexWrap: "nowrap",
                              overflowX: "auto",
                              paddingBottom: "6px",
                            }}
                          >
                            {meetingsTimes[selectedDay].meetings.map((day) => {
                              const isActive = selectedTime
                                ? selectedTime === day.fromTime
                                : false;
                              return (
                                <Col>
                                  <div
                                    className={`time-item ${
                                      isActive ? "time-item-active" : ""
                                    }`}
                                    key={day.fromTime}
                                    onClick={() =>
                                      setSelectedTime(day.fromTime)
                                    }
                                  >
                                    <span>
                                      {dayjs(day.fromTime, "HH:mm").format(
                                        "H:mm A",
                                      )}
                                    </span>
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        ) : (
                          <Typography.Text>
                            There's no time in this day
                          </Typography.Text>
                        )}
                      </Form.Item>
                    </>
                  )
                )}
              </>
            )}

            <Form.Item style={{ position: "sticky", bottom: 0 }}>
              <Button
                htmlType="submit"
                type="primary"
                className="w-100"
                style={{
                  borderRadius: "12px",
                  background: "#3F65E4",
                  height: "35px",
                }}
                loading={loading}
              >
                Confirm
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </section>
  );
}
