const LinkSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14.013"
    viewBox="0 0 14 14.013"
  >
    <g id="link_icon" transform="translate(-8330.696 -1196.97)">
      <path
        id="Union_1"
        data-name="Union 1"
        d="M1.146,13.029A3.49,3.49,0,0,1,0,10.334,3.266,3.266,0,0,1,.925,8.08C1.769,7.207,2.626,6.344,3.5,5.5a3.459,3.459,0,0,1,4.791.064.826.826,0,1,1-1.139,1.2,1.813,1.813,0,0,0-2.663.077Q3.356,7.972,2.222,9.108a1.814,1.814,0,0,0-.433,2.04,2.005,2.005,0,0,0,1.778,1.218,1.809,1.809,0,0,0,1.307-.549c.606-.606,1.214-1.211,1.816-1.82a.244.244,0,0,1,.283-.077,3.873,3.873,0,0,0,1.763.124.832.832,0,0,1,.123,0,2.052,2.052,0,0,1-.378.5c-.84.844-1.673,1.7-2.529,2.524a3.4,3.4,0,0,1-2.372.951A3.491,3.491,0,0,1,1.146,13.029ZM5.714,8.457a.827.827,0,0,1,.442-1.431.846.846,0,0,1,.726.26,1.812,1.812,0,0,0,2.171.263,1.907,1.907,0,0,0,.4-.313c.777-.779,1.559-1.551,2.332-2.335a1.73,1.73,0,0,0,.431-1.988,1.891,1.891,0,0,0-1.741-1.257,1.714,1.714,0,0,0-1.331.529Q8.219,3.1,7.3,4.029a.228.228,0,0,1-.268.069,3.907,3.907,0,0,0-1.8-.122.749.749,0,0,1-.1,0,2.579,2.579,0,0,1,.416-.532c.807-.809,1.611-1.62,2.423-2.424a3.467,3.467,0,0,1,4.9-.016A3.465,3.465,0,0,1,14,3.709a3.269,3.269,0,0,1-.929,2.234c-.84.869-1.692,1.729-2.564,2.566a3.456,3.456,0,0,1-4.791-.053Z"
        transform="translate(8330.696 1196.97)"
        fill={props.color || "#2d2d2d"}
      />
    </g>
  </svg>
);

export default LinkSVG;
