const WhiteChatSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50.1}
    height={50}
    viewBox="0 0 50 50.001"
    {...props}
  >
    <path
      fill={props.color || "#c7c7cc"}
      fillRule="evenodd"
      d="M25 0a25 25 0 0 1 17.686 42.688A25.115 25.115 0 0 1 14.468 47.7a8.326 8.326 0 0 0-2.573-.768 10.081 10.081 0 0 0-2.78.7c-2.033.7-4.564 1.568-6.437-.3s-1-4.392-.312-6.423A10.008 10.008 0 0 0 3.06 38.1a8.171 8.171 0 0 0-.784-2.64A25.085 25.085 0 0 1 7.324 7.321 24.831 24.831 0 0 1 25 0Zm0 3.489a21.369 21.369 0 0 0-15.21 6.3 21.618 21.618 0 0 0-4.311 24.287 10.984 10.984 0 0 1 1.07 4.024 12.578 12.578 0 0 1-.879 3.941c-.34 1-.854 2.5-.523 2.829s1.835-.193 2.833-.535a12.7 12.7 0 0 1 3.9-.889 10.808 10.808 0 0 1 3.972 1.054A21.529 21.529 0 0 0 40.22 9.787 21.37 21.37 0 0 0 25.006 3.49Zm9.182 20.152a2.325 2.325 0 1 1-.021 0Zm-9.326 0a2.325 2.325 0 1 1-.021 0Zm-9.327 0a2.325 2.325 0 1 1-.021 0Z"
      data-name="Iconly/Light-Outline/Chat"
    />
  </svg>
);
export default WhiteChatSVG;
