import { LoadingOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Select, Typography } from "antd";
import { BackArrow } from "assets/jsx-svg";
import companyContext from "context/companyContext";
import { initializeApp } from "firebase/app";
import { child, getDatabase, push, ref, set, update } from "firebase/database";
import { useContext, useEffect, useState } from "react";
import callService from "services/call.service";
import CustomerDeskService from "services/customerDesk.service";
import { axiosCatch } from "utils/axiosUtils";
import { firebaseConfig } from "utils/firebase.utils";

export default function LoginToService({ setSelectedService, setUserData }) {
  const [form] = Form.useForm();
  const { company } = useContext(companyContext);
  const [desks, setDesks] = useState([]);
  const [loading, setLoading] = useState(false);

  const db = getDatabase();
  useEffect(() => {
    initializeApp(firebaseConfig);
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    const { email, name, topic } = values;

    const reference = ref(db, `Company/${company.id}/Calls`);
    const CallKey = push(child(reference, "call")).key;
    const EmailKey = email.replace(/\./g, ",");

    const newRefCall = ref(db, `Company/${company.id}/Calls/call/${CallKey}`);
    const newRefCallUnAssigned = ref(
      db,
      `Company/${company.id}/Calls/CallUnAssigned/${CallKey}`,
    );
    const newRefCallCustomers = ref(
      db,
      `Company/${company.id}/Calls/customers/${EmailKey}`,
    );

    const newPostKey = push(newRefCallCustomers).key;

    set(newRefCall, {
      data: {
        id: CallKey,
        time: Date.now(),
        CustomerName: name,
        callType: "callType",
        email: email,
        status: "waiting",
        deskId: topic,
      },
    }).then(async () => {
      const data = {
        firebaseId: CallKey,
        fullName: name,
        email: email,
        deskId: topic
      };
      const res = await callService.directCall(data);
      setUserData({ ...values, ...res.data.data });
    });
    set(newRefCallUnAssigned, newRefCall.key);

    const customerData = {
      [newPostKey]: CallKey,
    };

    update(newRefCallCustomers, customerData).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await CustomerDeskService.get("CUSTOMER_SERVICE_DESK");
        setDesks(res.data.data.rows);
      } catch (err) {
        axiosCatch(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <div
        style={{ width: "18px", paddingInline: "14px" }}
        className="clickable"
        onClick={() => setSelectedService(null)}
      >
        <BackArrow color="#000" />
      </div>

      <Row
        justify="center"
        style={{
          marginTop: "18px",
          textAlign: "center",
          paddingInline: "14px",
        }}
      >
        <Col xs={24}>
          <Typography.Text className="fw-500 fz-16">
            Customer service center
          </Typography.Text>
        </Col>
        <Col xs={24}>
          <Typography.Text style={{ color: "#8E8E93" }} className="fz-10">
            Exceptional support for your needs
          </Typography.Text>
        </Col>
      </Row>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ marginTop: "20px", paddingInline: "14px" }}
        className="widget-chat-login"
      >
        <Form.Item
          name="email"
          label="Enter Your E-mail"
          rules={[{ required: true, message: "Please Enter Email" }]}
        >
          <Input placeholder="ex: ahmed@gmail.com" />
        </Form.Item>

        <Form.Item
          name="name"
          label="Enter Your Name"
          rules={[{ required: true, message: "Please Enter Name" }]}
        >
          <Input placeholder="ex: ahmed ali" />
        </Form.Item>

        <Form.Item
          name="topic"
          label="Subject or topic"
          rules={[
            { required: true, message: "Please Select Subject or topic" },
          ]}
        >
          <Select
            placeholder="select"
            options={desks?.map((desk) => ({
              label: desk.name,
              value: desk.id,
            }))}
          />
        </Form.Item>

        <Form.Item style={{ marginTop: "20px" }}>
          <Row>
            <button
              style={{ pointerEvents: loading && "none" }}
              className="next-btn w-100"
            >
              {loading ? <LoadingOutlined /> : "Next"}
            </button>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
}
