import axios from "axios";
import { API_BASE } from "./config";

const SERVICE_BASE = API_BASE + "customer/meeting/";

const book = (data) => {
  return axios.post(SERVICE_BASE + "book", data);
};

const getByDay = (date, deskId) => {
  return axios.get(SERVICE_BASE + `get-by-day?date=${date}&deskId=${deskId}`);
};

const CustomerMeetingService = {
  book,
  getByDay,
};

export default CustomerMeetingService;
