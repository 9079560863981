const DeleteSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={15.9}
    viewBox="0 0 14.563 16"
    {...props}
  >
    <path
      fill={props.color || "#f40055"}
      d="M3.656 16A2.173 2.173 0 0 1 1.5 13.818V4.363H.75A.744.744 0 0 1 0 3.636a.743.743 0 0 1 .75-.728h2.906V2.18A2.2 2.2 0 0 1 5.844-.002H8.75a2.223 2.223 0 0 1 1.688.8 2.011 2.011 0 0 1 .5 1.381v.728h2.906a.716.716 0 0 1 .719.728.717.717 0 0 1-.719.727h-.719v9.455a2.18 2.18 0 0 1-2.187 2.182Zm-.719-2.182a.717.717 0 0 0 .719.727h7.281a.738.738 0 0 0 .719-.727V4.394l-8.719-.028ZM9.468 2.909v-.728a.738.738 0 0 0-.094-.375v-.015a.816.816 0 0 0-.625-.338H5.843a.718.718 0 0 0-.719.728v.728Zm-1.437 8.727V7.273a.718.718 0 0 1 .719-.729.718.718 0 0 1 .719.729v4.363a.717.717 0 0 1-.719.728.717.717 0 0 1-.719-.728Zm-2.906 0V7.273a.718.718 0 0 1 .719-.729.718.718 0 0 1 .719.729v4.363a.717.717 0 0 1-.719.728.717.717 0 0 1-.719-.728Z"
      data-name="Path 50945"
    />
  </svg>
);
export default DeleteSVG;
