import axios from "axios";
import { API_BASE } from "./config";

const SERVICE_BASE = API_BASE + "customer/";

const directCall = (data) => {
  return axios.post(SERVICE_BASE + "direct-call/call", data);
};

const callService = {
  directCall,
};

export default callService;
