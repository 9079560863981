const AppsSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.1}
    height={17.1}
    data-name="Iconly/Light-Outline/Category"
    viewBox="0 0 17 17"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M3.705 9.59c3.547 0 3.705 1.2 3.705 3.705 0 1.254 0 2.145-.527 2.8-.606.752-1.709.907-3.179.907s-2.573-.154-3.179-.907A4.312 4.312 0 0 1 0 13.317l.654-.022H0C0 10.793.158 9.59 3.705 9.59Zm9.59 0c3.547 0 3.705 1.2 3.705 3.705 0 1.254 0 2.145-.527 2.8-.606.752-1.709.907-3.179.907s-2.573-.154-3.179-.907a4.312 4.312 0 0 1-.527-2.777l.654-.022H9.59c0-2.503.158-3.706 3.705-3.706Zm-9.41 1.31h-.18c-2.274 0-2.391.163-2.4 2.072v.744a2.957 2.957 0 0 0 .236 1.56c.231.286.917.418 2.161.418s1.93-.133 2.161-.419a3.935 3.935 0 0 0 .237-1.959c0-2.18 0-2.408-2.215-2.416Zm9.59 0h-.18c-2.274 0-2.391.163-2.4 2.072v.744a2.957 2.957 0 0 0 .236 1.56c.231.286.917.418 2.161.418s1.93-.133 2.161-.419a3.935 3.935 0 0 0 .236-1.957c.003-2.182.003-2.41-2.214-2.418ZM3.705 0C7.252 0 7.41 1.2 7.41 3.705c0 1.254 0 2.145-.527 2.8-.606.752-1.709.907-3.179.907S1.132 7.256.527 6.5A4.312 4.312 0 0 1 0 3.727l.654-.022H0C0 1.2.158 0 3.705 0Zm9.59 0C16.842 0 17 1.2 17 3.705c0 1.254 0 2.145-.527 2.8-.606.752-1.709.907-3.179.907s-2.573-.154-3.179-.907a4.312 4.312 0 0 1-.525-2.778l.654-.022H9.59C9.59 1.2 9.748 0 13.295 0Zm-9.41 1.308h-.18c-2.274 0-2.391.163-2.4 2.072v.744a2.957 2.957 0 0 0 .236 1.56c.231.286.917.418 2.161.418s1.93-.133 2.161-.419A3.935 3.935 0 0 0 6.1 3.726c0-2.18 0-2.408-2.215-2.418Zm9.59 0h-.18c-2.274 0-2.391.163-2.4 2.072v.744a2.957 2.957 0 0 0 .236 1.56c.231.286.917.418 2.161.418s1.93-.133 2.161-.419a3.935 3.935 0 0 0 .236-1.957c.003-2.18.003-2.408-2.214-2.418Z"
    />
  </svg>
);
export default AppsSVG;
