const AttatchSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.4}
    height={18.4}
    viewBox="0 0 18.418 18.418"
    {...props}
  >
    <path
      fill="#8e8e93"
      d="M3.552 14.866a5.022 5.022 0 0 1 0-7.104l4.257-4.257a.558.558 0 0 1 .79.789L4.342 8.55a3.907 3.907 0 1 0 5.525 5.525l5.262-5.262a2.419 2.419 0 0 0-3.42-3.42l-4.832 4.831a.93.93 0 0 0 1.316 1.315l3.778-3.778a.558.558 0 0 1 .79.789l-3.778 3.778a2.047 2.047 0 0 1-2.895-2.894l4.83-4.83a3.535 3.535 0 1 1 5 4.999l-5.262 5.262a5.022 5.022 0 0 1-7.104 0Z"
      data-name="Path 50950"
    />
  </svg>
);
export default AttatchSVG;
