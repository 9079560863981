import axios from "axios";
import { API_BASE } from "./config";

const SERVICE_BASE = API_BASE + "customer/widget/";

const getData = (hash) => {
  return axios.get(SERVICE_BASE + `get-data/${hash}`);
};

const WidgetService = {
  getData,
};

export default WidgetService;
