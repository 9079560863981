const BackArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={7.001}
    height={12.15}
    viewBox="0 0 7 12.157"
    {...props}
  >
    <path
      fill={props.color || "#fff"}
      d="M6.131 12.157a.868.868 0 0 1-.614-.253L.254 6.685A.85.85 0 0 1 0 6.077a.849.849 0 0 1 .254-.607L5.517.251a.865.865 0 0 1 .614-.253.865.865 0 0 1 .614.253.849.849 0 0 1 .254.607.85.85 0 0 1-.254.608L2.227 5.947a.183.183 0 0 0-.055.13.184.184 0 0 0 .055.131l4.518 4.481a.85.85 0 0 1 .254.608.851.851 0 0 1-.254.607.872.872 0 0 1-.614.253Z"
      data-name="Path 50951"
    />
  </svg>
);
export default BackArrow;
