import axios from "axios";
import { API_BASE } from "./config";

const SERVICE_BASE = API_BASE + "common/";

const uploadFile = (file) => {
  const formData = new FormData();
  formData.set("file", file);

  return axios.post(SERVICE_BASE + `upload-file`, formData, {
    headers: {
      "Content-Type": "Multipart/form-data",
    },
  });
};

const CommonService = {
  uploadFile,
};

export default CommonService;
