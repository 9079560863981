const DiskTicket = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50.1}
    height={50.1}
    data-name="Group 50634"
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      fill={props.color || "#c7c7cc"}
      fillRule="evenodd"
      d="M40.584 4.651A9.378 9.378 0 0 1 50 13.969v6.033a1.747 1.747 0 0 1-1.744 1.749 3.256 3.256 0 1 0 0 6.512A1.745 1.745 0 0 1 50 30.002v6.028a9.378 9.378 0 0 1-9.414 9.321H9.414A9.378 9.378 0 0 1 0 36.03v-6.028a1.745 1.745 0 0 1 1.744-1.744 3.291 3.291 0 0 0 3.319-3.256 3.128 3.128 0 0 0-3.319-3.06A1.747 1.747 0 0 1 0 20.197v-6.228a9.378 9.378 0 0 1 9.416-9.318Zm0 3.488h-9.54v4.468a1.744 1.744 0 1 1-3.488 0V8.139H9.416a5.886 5.886 0 0 0-5.926 5.83v4.693a6.513 6.513 0 0 1 5.063 6.34 6.769 6.769 0 0 1-5.063 6.519v4.509a5.886 5.886 0 0 0 5.926 5.83h18.14v-3.462a1.744 1.744 0 1 1 3.488 0v3.462h9.542a5.886 5.886 0 0 0 5.926-5.83v-4.51a6.728 6.728 0 0 1 0-13.037v-4.514a5.886 5.886 0 0 0-5.928-5.83ZM29.3 17.451a1.745 1.745 0 0 1 1.744 1.744v11.213a1.744 1.744 0 0 1-3.488 0V19.196a1.745 1.745 0 0 1 1.744-1.745Z"
    />
  </svg>
);
export default DiskTicket;
