import * as React from "react";

const SendSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.001}
    height={13.9}
    data-name="Icon - Send"
    viewBox="0 0 14 13.905"
    {...props}
  >
    <path
      fill={props.color || "#9a42a9"}
      d="M5.986 9.007a.759.759 0 0 0 .142-.107l3.649-3.653a.757.757 0 0 0-1.07-1.07L5.058 7.825a.759.759 0 0 0-.111.14L.436 5.207a.911.911 0 0 1 .223-1.652L12.835.037a.91.91 0 0 1 1.125 1.136l-3.612 12.081a.911.911 0 0 1-1.651.212Z"
    />
  </svg>
);

export default SendSVG;
